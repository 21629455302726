$(function(){

	function isVisible(row, container){

		var elementTop = $(row).offset().top,
			elementHeight = $(row).height(),
			containerTop = container.scrollTop(),
			containerHeight = container.height();

		return ((((elementTop - containerTop) + elementHeight) > 0) && ((elementTop - containerTop) < containerHeight));
	}

	$(window).scroll(function(){
		$('.digit-box:not(.inited)').each(function(){
			if(isVisible($(this), $(window)))
				{
					initIncrement()
					$(this).addClass('inited')
					//console.log($(this).attr('class')+" is visible");
				}
			;
		});
	});

	$('.js-match-height').matchHeight()

	function initIncrement(){
		$('.js-inc-animate, .js-inc-animate div').each(function(i, el){
			$(el).spincrement({
				thousandSeparator: "",
				duration         : 1200,
				// from:$(el).data('from'),
				// to:$(el).data('to')
			})
		})
	}

	/**
	 * Photobox
	 */

	$('.js-photobox').photobox('.js-photobox-item')

	/**
	 * Фиксирование шапки
	 */
	var bIndex = $('body').hasClass('index')
	var bmenuFixedFromSize = (window.matchMedia("(min-width: 1599px)").matches) ? true : false
	$(window).scroll(function(e){
		if($(this).scrollTop() > $('.header').innerHeight() && !bIndex && bmenuFixedFromSize)
			{
				//$('.header-nav').addClass('fixed-top bg-white');
			} else
			{
				//$('.header-nav').removeClass('fixed-top bg-white');
			}
	})

	/**
	 * Плавный скролл
	 */
	$('body').on('click', 'a[href*="#"]:not(.btn):not([data-toggle])', function(event){
		//let target = $(this.hash);
		var target = this.hash.slice(1)
		if(target)
			{
				var selector = '[name=' + target + ']';
				target = target.length ? $('#' + target) : $(selector);
				if(target.length)
					{
						// Only prevent default if animation is actually gonna happen
						event.preventDefault();
						$('html, body').animate({
							scrollTop: $(target).offset().top - 45
						}, 500, function(){

						});
					}
			}
	})

	/**
	 * ScrollTop[
	 * @type {*}
	 */
	var scrollEl = $('.js-scroll-top');
	$(window).scroll(function(){
		scrollEl[$(this).scrollTop() > 70 ? 'fadeIn' : 'fadeOut']()
	});
	scrollEl.click(function(){
		$('body,html').animate({scrollTop: 0}, 800);
		return false;
	});

	/**
	 * Маска для телефонов
	 * @type {{onKeyPress: onKeyPress, placeholder: string}}
	 */
	var customOptions = {
		onKeyPress : function(val, e, field, options){

			if(val.replace(/\D/g, '').length === 2)
				{
					val = val.replace('8', '');
					field.val(val);
				}
			field.mask("+7 (999) 999-99-999", options);
		},
		placeholder: "+7 (___) ___-____"
	};

	// let selectors = ' [name="phone"]'
	var selectors = '[name="username"], [name="phone"], [name="employer_phone"]'
	$('body').on('focus', selectors, function(){
		$('body').find(selectors).mask('+7 (000) 000-00-000', customOptions);
	}).on('blur', function(){
		$('body').find(selectors).attr('placeholder', 'Номер телефона *');
	})

	// console.log('123')
	$('.js-tech-slider-photo').owlCarousel({
		navText: ['<i class="fa fa-caret-left"></i>', '<i class="fa fa-caret-right"></i>'],
		items  : 1,
		dots   : false,
		nav    : true,
	})
	$('.js-tech-slider-text').owlCarousel({
		items: 1,
		dots : true,
		nav  : false,
	})

	$('.sales .owl-carousel').owlCarousel({
		items: 1,
		dots : false,
	})

	$('.license .owl-carousel').owlCarousel({
		responsive: {
			0   : {
				items: 1,
				nav  : false,
				dots : true,
			},
			1024: {
				items: 3,
				nav  : false,
				dots : true,
			}
		},
		items     : 3,
		margin    : 30,
	})
	$('.partners-list .owl-carousel').owlCarousel({
		responsive: {
			0   : {
				items: 2,
				nav  : false,
				dots : true,
			},
			1024: {
				items: 4,
				nav  : false,
				dots : true,
			}
		},
		margin            : 10,
		loop              : true,
		autoplay          : true,
		autoplayTimeout   : 2500,
		autoplayHoverPause: true,
		dots              : false,
	})

	var owlReviewCarousel = $('.js-reviews-carousel');
	owlReviewCarousel.on('changed.owl.carousel', function(event){
		$('.js-review-matchheight').matchHeight()
	})
	owlReviewCarousel.on('initialized.owl.carousel', function(event){
		$('.js-review-matchheight').matchHeight()
	})
	owlReviewCarousel.owlCarousel({
		items  : 1,
		margin : 0,
		navText: ['<i class="fa fa-caret-left"></i>', '<i class="fa fa-caret-right"></i>'],
		nav    : false,
		dots   : true,
	})

	$('.js-review-matchheight').matchHeight()

	var owlPatentCarousel = $('.js-patent-carousel');

	owlPatentCarousel.owlCarousel({
		margin            : 20,
		navText           : ['<i class="fa fa-caret-left"></i>', '<i class="fa fa-caret-right"></i>'],
		nav               : false,
		dots              : false,
		autoplay          : true,
		autoplayTimeout   : 4500,
		autoplayHoverPause: true,
		loop              : true,
		responsive: {
			0   : {
				items: 2,
				nav  : false,
				dots : true
			},
			768: {
				items: 3,
				nav  : true,
				dots : false
			},
			1024: {
				items: 3,
				nav  : true,
				dots : false
			},
			1200: {
				items: 4,
				nav  : true,
				dots : false
			},
		},
	})

	$('.photo-list .owl-carousel').owlCarousel({
		navText   : ['<i class="fa fa-caret-left"></i>', '<i class="fa fa-caret-right"></i>'],
		responsive: {
			0   : {
				items: 1,
				nav  : false,
				dots : true
			},
			1024: {
				items: 3,
				nav  : true,
				dots : false
			}
		},
		autoplay          : true,
		autoplayTimeout   : 4500,
		autoplayHoverPause: true,
		margin    : 30,
	})

	$('.photo-carousel.owl-carousel').owlCarousel({
		navText           : ['<i class="fa fa-caret-left"></i>', '<i class="fa fa-caret-right"></i>'],
		responsive        : {
			0   : {
				items: 3,
				nav  : false,
				dots : false
			},
			1024: {
				items: 3,
				nav  : false,
				dots : false
			}
		},
		autoplay          : true,
		autoplayTimeout   : 1000,
		autoplayHoverPause: true,
		items             : 8,
		loop              : true,
		margin            : 10,
	})

	$('.hotel-photo-carousel.owl-carousel').owlCarousel({
		navText           : ['<i class="fa fa-caret-left"></i>', '<i class="fa fa-caret-right"></i>'],
		responsive        : {
			0   : {
				items: 1,
				nav  : false,
				dots : false
			},
			1024: {
				items: 8,
				nav  : false,
				dots : false
			}
		},
		autoplay          : true,
		autoplayTimeout   : 3500,
		autoplayHoverPause: true,
		loop              : false,
		margin            : 10,
	})

	$('.js-medialib2-photo-carousel').owlCarousel({
		navText           : ['<i class="fa fa-caret-left"></i>', '<i class="fa fa-caret-right"></i>'],
		responsive        : {
			0   : {
				items: 1,
				nav  : false,
				dots : false
			},
			1024: {
				items: 3,
				nav  : false,
				dots : false
			}
		},
		margin            : 10,
		loop              : true,
		autoplay          : true,
		autoplayTimeout   : 2500,
		autoplayHoverPause: true,
		dots              : false,
	})
	$('.js-photofile-carousel').owlCarousel({
		navText           : ['<i class="fa fa-caret-left"></i>', '<i class="fa fa-caret-right"></i>'],
		responsive        : {
			0   : {
				items: 1,
				nav  : false,
				dots : false
			},
			1024: {
				items: 2,
				nav  : false,
				dots : false
			}
		},
		margin            : 10,
		loop              : true,
		autoplay          : true,
		autoplayTimeout   : 2500,
		autoplayHoverPause: true,
		dots              : false,
	})
	$('.js-council-carousel ').owlCarousel({
		navText           : ['<i class="fa fa-caret-left"></i>', '<i class="fa fa-caret-right"></i>'],
		responsive        : {
			0   : {
				items: 1,
				nav  : false,
				dots : false
			},
			1024: {
				items: 5,
				nav  : true,
				dots : true
			}
		},
		autoplay          : true,
		autoplayTimeout   : 3500,
		autoplayHoverPause: true,
		loop              : false,
		margin            : 10,
	})

	$('body').on('shown.bs.tab', '[data-toggle]', function(e){
		//$('.js-col-match-height').matchHeight()
	})
	$('body').on('click', '.removeNotitifcation', function(e){
		setCookie('notification_1', 1, 99999)
		$('.notification').remove();

	})

	$('body').on('hidden.bs.modal hide.bs.modal', '.modal', function(e){
		// do something...
		$('body').find('.modal-backdrop').remove();
	})
	$('body').on('click', '.modal .close', function(e){
		// do something...
		//$('body').find('.modal-backdrop').remove();
	})

	var actionBtn = $('[data-action]');
	actionBtn.on('click', function(){
		var action = $(this).data('action')
		var id = $(this).data('id')
		var target = $(this).data('target')
		if(action == 'load_modal' && id > 0 && target)
			{
				$.ajax({
					url    : '/ajax/form.php?id=' + id,
					success: function(data){
						var targetSel = '#' + target
						var targetEl = $(targetSel)
						targetEl.html('');
						targetEl.append(data)
					}
				})
			}
	})
})
if(window.BX)
	{
		BX.message({
			'JS_REQUIRED'                       : 'Заполните это поле!',
			'JS_FORMAT'                         : 'Неверный формат!',
			'JS_FILE_EXT'                       : 'Недопустимое расширение файла!',
			'JS_PASSWORD_COPY'                  : 'Пароли не совпадают!',
			'JS_PASSWORD_LENGTH'                : 'Минимум 6 символов!',
			'JS_ERROR'                          : 'Неверно заполнено поле!',
			'JS_FILE_SIZE'                      : 'Максимальный размер 5мб!',
			'JS_FILE_BUTTON_NAME'               : 'Выберите файл',
			'JS_FILE_DEFAULT'                   : 'Файл не найден',
			'JS_DATE'                           : 'Некорректная дата!',
			'JS_DATETIME'                       : 'Некорректная дата/время!',
			'JS_REQUIRED_LICENSES'              : 'Согласитесь с условиями',
			'S_CALLBACK'                        : 'Заказать звонок',
			'S_QUESTION'                        : 'Задать вопрос',
			'S_ASK_QUESTION'                    : 'Задать вопрос',
			'ERROR_INCLUDE_MODULE_DIGITAL_TITLE': 'Ошибка подключения модуля &laquo;Аспро: Digital-компания&raquo;',
			'ERROR_INCLUDE_MODULE_DIGITAL_TEXT' : 'Ошибка подключения модуля &laquo;Аспро: Digital-компания&raquo;.<br />Пожалуйста установите модуль и повторите попытку',
			'S_SERVICES'                        : 'Наши услуги',
			'S_SERVICES_SHORT'                  : 'Услуги',
			'S_TO_ALL_SERVICES'                 : 'Все услуги',
			'S_CATALOG'                         : 'Каталог товаров',
			'S_CATALOG_SHORT'                   : 'Каталог',
			'S_TO_ALL_CATALOG'                  : 'Весь каталог',
			'S_CATALOG_FAVORITES'               : 'Наши товары',
			'S_CATALOG_FAVORITES_SHORT'         : 'Товары',
			'S_NEWS'                            : 'Новости',
			'S_TO_ALL_NEWS'                     : 'Все новости',
			'S_COMPANY'                         : 'О компании',
			'S_OTHER'                           : 'Прочее',
			'S_CONTENT'                         : 'Контент',
			'T_JS_ARTICLE'                      : 'Артикул: ',
			'T_JS_NAME'                         : 'Наименование: ',
			'T_JS_PRICE'                        : 'Цена: ',
			'T_JS_QUANTITY'                     : 'Количество: ',
			'T_JS_SUMM'                         : 'Сумма: ',
			'FANCY_CLOSE'                       : 'Закрыть',
			'FANCY_NEXT'                        : 'Вперед',
			'FANCY_PREV'                        : 'Назад',
			'CUSTOM_COLOR_CHOOSE'               : 'Выбрать',
			'CUSTOM_COLOR_CANCEL'               : 'Отмена',
			'S_MOBILE_MENU'                     : 'Меню',
			'DIGITAL_T_MENU_BACK'               : 'Назад',
			'DIGITAL_T_MENU_CALLBACK'           : 'Обратная связь',
			'DIGITAL_T_MENU_CONTACTS_TITLE'     : 'Будьте на связи',
			'TITLE_BASKET'                      : 'В корзине товаров на #SUMM#',
			'BASKET_SUMM'                       : '#SUMM#',
			'EMPTY_BASKET'                      : 'Корзина пуста',
			'TITLE_BASKET_EMPTY'                : 'Корзина пуста',
			'BASKET'                            : 'Корзина',
			'SEARCH_TITLE'                      : 'Поиск',
			'SOCIAL_TITLE'                      : 'Оставайтесь на связи',
			'LOGIN'                             : 'Войти',
			'MY_CABINET'                        : 'Мой кабинет',
			'SUBSCRIBE_TITLE'                   : 'Будьте всегда в курсе',
			'HEADER_SCHEDULE'                   : 'Время работы',
			'SEO_TEXT'                          : 'SEO описание',
			'COMPANY_IMG'                       : 'Картинка компании',
			'COMPANY_TEXT'                      : 'Описание компании',
			'JS_RECAPTCHA_ERROR'                : 'Пройдите проверку',
			'JS_PROCESSING_ERROR'               : 'Согласитесь с условиями!',
			'CONFIG_SAVE_SUCCESS'               : 'Настройки сохранены',
			'CONFIG_SAVE_FAIL'                  : 'Ошибка сохранения настроек',
			'RELOAD_PAGE'                       : 'Обновить страницу',
			'REFRESH'                           : 'Поменять картинку',
			'RECAPTCHA_TEXT'                    : 'Подтвердите, что вы не робот'
		})

	}
// another scripts
console.log(123);